import { UserContext } from 'context/AuthContext/UserContext'
import React, { useContext, useEffect } from 'react'
import authService from 'shared/services/auth.service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import purchaseOrderService from 'shared/services/purchase-order-service'
import Actions from './Actions'
import { useMutation, useQuery } from 'react-query'
import { downloadPDF, exportJsonToCsvCustomRows, FilterUniqueIds } from 'shared/util/helper'
import moment from 'moment'
export const usePurchaseOrderDetailsHooks = (props) => {
  const { socket } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isCancelPoLoading, setIsCancelPoLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [pId, setPId] = useState()
  const [isSubmitting, setisSubmitting] = useState(false)
  const [sku, setSku] = useState()
  const [products, setProducts] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [newMessage, setNewMessage] = useState(0)
  const [rows, setRows] = useState([])
  const [content, setContent] = useState({ history: false, proposal: false, validation: false, cancel: false })
  const [validating, setValidating] = useState(false)
  const [productsSku, setProductsSku] = useState([])
  const [vendorData, setVendorData] = useState([])
  const [supplierData, setSupplierData] = useState('')
  const contextObj = useContext(UserContext)
  const param = useParams()
  const { showToast } = useContext(UserContext)
  const userType = contextObj?.userData?.user?.pomUserType === 1 ? true : false
  const [odooVendors, setOdooVendors] = useState({})

  const columns = [
    { id: 'name', label: 'Image', minWidth: 50 },
    { id: 'sku', label: 'Sku', minWidth: 130 },
    { id: 'vendor', label: 'Vendor\u00a0Reference\u00a0Number', minWidth: 100 },
    { id: 'size', label: 'Size', minWidth: 100 },
    { id: 'SQMeterPerPiece', label: 'SQ\u00a0Meter\u00a0Per\u00a0Piece', minWidth: 20 },
    { id: 'FOB', label: 'FOB\u00a0Price', minWidth: 20 },
    { id: 'totalFOB', label: 'Total\u00a0FOB', minWidth: 50 },
    { id: 'totalSqm', label: 'Total\u00a0Sqm', minWidth: 50 },
    { id: 'expectedQty', label: 'Expected\u00a0Qty', minWidth: 20 },
    { id: 'proposedQty', label: 'Proposed\u00a0Qty', minWidth: 20 },
    { id: 'validatedQty', label: 'Validated\u00a0Qty', minWidth: 20 },
    { id: 'action', label: 'Action', minWidth: 20 }
  ]

  async function createData(
    name,
    sku,
    vendor,
    size,
    totalSqm,
    expectedQty,
    proposedQty,
    validatedQty,
    action,
    SQMeterPerPiece,
    FOB,
    totalFOB,
    id,
    upc,
    width,
    length
  ) {
    return {
      name,
      sku,
      vendor,
      size,
      SQMeterPerPiece,
      FOB,
      totalFOB,
      totalSqm,
      expectedQty,
      proposedQty,
      validatedQty,
      action,
      id,
      upc,
      width,
      length
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { refetch: vendorRefetch } = useQuery(
    ['vendor', contextObj?.userData?.user?.companyId],
    async () => {
      const response = await authService.getVendors(contextObj?.userData?.user?.companyId)
      return response
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const FilterVendors = FilterUniqueIds(response.data, contextObj?.userData.user?.companyId)
        setVendorData(FilterVendors)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )
  function expectedQty(id, productData) {
    const product = productData.find((product) => product.productId === id)
    return product ? Number(product.expectedQty) : 0
  }
  function productProposedQty(id, productData) {
    const product = productData.find((product) => product.productId === id)
    return product ? product.proposedQty.slice(-1)[0]?.qty : '-'
  }
  function validatedQty(id, productData) {
    const product = productData.find((product) => product.productId === id)
    const quantity = product.validatedQty ? Number(product.validatedQty?.qty ?? 0) : 0
    return quantity
  }
  function getVendorReferenceNumber(product) {
    return product && product.shipping && product.shipping.length > 0
      ? product.shipping[0]?.vendorReferenceNumber
      : null
  }
  const fetchProductsOnIdsMutation = useMutation(
    async (params) => {
      const { ids, productsData, skuToFobPriceMap, purchaseOrderDetail } = params
      let arr = []
      try {
        const response = await purchaseOrderService.getProductsOnIds(ids)
        setIsLoading(false)
        setProductsSku(response.data)
        await Promise.all(
          await response?.data?.map(async (product) => {
            const metricSize = (product?.measurements[0]?.length * product?.measurements[0]?.width) / 10000
            const validateQty = await validatedQty(product.id, productsData)
            const expectQty = validateQty
              ? await expectedQty(product.id, productsData)
              : await expectedQty(product.id, productsData)
            const proposedQty = validateQty
              ? await productProposedQty(product.id, productsData)
              : await productProposedQty(product.id, productsData)
            let supplierName = getVendorReferenceNumber(product)
            const fobprice = skuToFobPriceMap[product.id]
              ? skuToFobPriceMap[product.id]
              : product?.shipping[0]?.fobCostPerPc
            let total = metricSize * (validateQty || proposedQty || expectQty)
            const TotalFOB = fobprice * (validateQty || proposedQty || expectQty)
            arr.push(
              await createData(
                product?.images[0],
                product?.sku,
                supplierName ?? 'N/A',
                product?.measurements[0]?.size ?? 'N/A',
                total ?? 0,
                expectQty ?? 0,
                proposedQty ?? 0,
                validateQty ?? 0,
                <Actions
                  setModal={setModal}
                  productData={product}
                  setPId={setPId}
                  setSku={setSku}
                  poDetails={purchaseOrderDetail}
                  setContent={setContent}
                  product={productsData}
                />,
                metricSize,
                fobprice,
                TotalFOB.toFixed(2),
                product.id,
                product?.upc,
                product?.measurements[0]?.width,
                product?.measurements[0]?.length
              )
            )
          })
        )
        setRows(arr)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    },
    {
      onSuccess: () => {},
      onError: (err) => {
        console.error('Error:', err)
      }
    }
  )
  const { isLoading: isPoLoading } = useQuery(
    ['purchaseOrder', param.poNumber],
    async () => {
      await fetchPurchaseOrderUseOuery()
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: () => {}
    }
  )
  const fetchPurchaseOrderUseOuery = async () => {
    try {
      const response = await purchaseOrderService.getSinglePurchaseOrder(param.poNumber)
      setSupplierData(response?.data)
      await setProducts(response?.data?.products)
      let productData = []
      let skuToFobPriceMap = {}
      response?.data?.products?.forEach((product) => {
        const sku = product?.productId
        const fobPrice = parseFloat(product?.price_unit ?? 0)
        skuToFobPriceMap[sku] = fobPrice
      })
      setPurchaseOrderDetail(response.data)
      response?.data?.products?.map((product) => {
        productData.push(product?.productId)
      })
      await Promise.all([
        getPoChats.mutate({ poId: response?.data?._id, page: 0, limit: 50 }),
        fetchProductsOnIdsMutation.mutate({
          ids: productData,
          productsData: response?.data?.products,
          skuToFobPriceMap: skuToFobPriceMap,
          purchaseOrderDetail: response.data
        })
      ])
      const odooRefetchApi = await vendorRefetch()
      const details =
        Array.isArray(odooRefetchApi?.data?.data) &&
        odooRefetchApi?.data?.data?.length &&
        odooRefetchApi.data.data.filter((item) => item.id === response?.data?.supplier)
      setOdooVendors(details[0])
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  const [messages, setMessages] = useState([])
  const updateMessage = () => {
    const userId = contextObj?.userData?.user?.id
    if (userId && messages && messages.users && messages.users.includes(userId)) {
      socket.emit('UpdateMessage', {
        poId: purchaseOrderDetail?._id,
        ids: [messages?._id]
      })
    }
  }
  useEffect(() => {
    const userId = contextObj?.userData?.user?.id
    if (socket && userId) {
      socket.on(purchaseOrderDetail._id, (data) => {
        if (!isOpen) {
          setNewMessage(newMessage + 1)
        }
        if (userId && data && data.users && !data.users.includes(userId)) {
          data.lastMessage.isRead = false
        }
        setMessages(data)
      })
    }
  }, [socket, messages, contextObj.userData.user.id, isOpen])
  const createChatId =
    contextObj?.userData?.user?.pomUserType === 2
      ? [contextObj.userData.user.id, purchaseOrderDetail?.userId]
      : [contextObj.userData.user.id, purchaseOrderDetail?.supplier]
  const sendMessage = (message, type) => {
    if (message !== '') {
      const companyIdToSave =
        contextObj.userData.user.pomusertype === 1 ? contextObj.userData.user.companyId : purchaseOrderDetail.companyId
      if (!message) {
        return showToast('Please add messages First', false)
      }
      socket.emit('Message', {
        userId: contextObj.userData.user.id,
        companyId: companyIdToSave,
        poId: purchaseOrderDetail._id,
        userName: contextObj.userData.user.fName,
        message: message.trim(),
        isRead: false,
        type: type,
        socketID: socket.id,
        poNumber: purchaseOrderDetail.poNumber,
        chatType: 'Purchase Order',
        chatCreateUsers: createChatId
      })
    }
  }
  const getPoChats = useMutation(
    async (params) => {
      const { poId, page, limit } = params
      const response = await purchaseOrderService.getAllPoChats(poId, page, limit)
      return response
    },
    {
      onSuccess: async (response) => {
        const messages = (await response?.data[0]?.messages) || []
        setMessages(response.data[0])
        const unreadMessageCount = messages.filter((message) => message.isRead === false)
        setNewMessage(unreadMessageCount.length)
      },
      onError: () => {
        setMessages({ error: 'Unable to Load Convertations' })
      }
    }
  )
  const setProposedQty = (sku, proposedQty) => {
    const productIndex = rows.findIndex((product) => product.sku === sku)
    if (productIndex !== -1) {
      const updatedPoDetails = [...rows]
      updatedPoDetails[productIndex].proposedQty = proposedQty
      updatedPoDetails[productIndex].totalFOB = (updatedPoDetails[productIndex].FOB * proposedQty).toFixed(2)
      updatedPoDetails[productIndex].totalSqm = (updatedPoDetails[productIndex].SQMeterPerPiece * proposedQty).toFixed(
        5
      )
      setRows(updatedPoDetails)
    }
  }
  const submitHandlerMutationCall = useMutation(
    async (params) => {
      const { PoNumber, ProductId, poSku } = params
      const response = await purchaseOrderService.UpdatePoSkuProposedQty(PoNumber, ProductId, poSku)
      return await { response: response?.data, payload: poSku }
    },
    {
      onSuccess: async (response) => {
        const data = await response?.response?.data
        const payload = await response?.payload
        if (data) {
          let data_to_save = {
            user_id: userType ? data?.supplier : data?.userId,
            companyId: userType ? data?.supplierCompany : data?.companyId,
            redirect_uri: data.poNumber,
            type: 'PURCHASE_ORDER',
            title: 'New Notification Received',
            message: `POV: ${data.poNumber} ${userType ? 'Buyer' : 'Vendor'} proposed qty ${payload?.qty} for ${
              payload?.sku
            }`,
            created_at: +new Date()
          }
          await purchaseOrderService.createNotification(data_to_save)
        }
      },
      onError: (err) => {
        setModal(false)
        setValidating(false)
        console.log('Error in submitHandlerMutationCall :', err)
      }
    }
  )
  const submitHandler = async (qty, PoNumber, ProductId) => {
    let arr = []
    let Sku
    setisSubmitting(true)
    productsSku.map((sku) => {
      if (sku.id == ProductId) {
        Sku = sku.sku
      }
    })
    vendorData.map((item) => {
      if (item?.id == supplierData?.supplier) {
        arr.push(item)
      }
    })
    try {
      const poSku = {
        qty: qty,
        userId: contextObj.userData.user.id,
        userEmail: contextObj?.userData?.user?.email,
        user: contextObj.userData.user.fName + ' ' + contextObj.userData.user.lName,
        companyName: arr[0]?.companyName,
        companyId: arr[0]?.companyId,
        email: arr[0]?.email,
        fname: arr[0]?.fName,
        poNumber: supplierData.poNumber,
        sku: Sku
      }
      await submitHandlerMutationCall.mutateAsync({ PoNumber: PoNumber, ProductId: ProductId, poSku: poSku })
      await updatePOStatusMutation.mutateAsync({ PoNumber, status: 'Proposal' })
      setModal(false)
      setProposedQty(sku, qty)
      return true
    } catch {
      setisSubmitting(false)
      setModal(false)
    } finally {
      setModal(false)
      setisSubmitting(false)
    }
  }
  const setValidatedQty = (sku, validatedQty) => {
    const productIndex = rows.findIndex((product) => product.sku === sku)
    if (productIndex !== -1) {
      const updatedPoDetails = [...rows]
      updatedPoDetails[productIndex].validatedQty = validatedQty
      updatedPoDetails[productIndex].totalFOB = (updatedPoDetails[productIndex].FOB * validatedQty).toFixed(2)
      updatedPoDetails[productIndex].totalSqm = (updatedPoDetails[productIndex].SQMeterPerPiece * validatedQty).toFixed(
        5
      )
      setRows(updatedPoDetails)
    }
  }
  const { mutate: poPublishUseMutation } = useMutation(
    async (values) => await purchaseOrderService.publishPurchaseOrder(values),
    {
      onSuccess: () => {},
      onError: (err) => {
        console.log('Error Publishing the PO :', err)
      }
    }
  )
  const handlePoPublish = async (event) => {
    if (event) {
      const form = event?.currentTarget
      if (form?.checkValidity() === false) {
        event?.preventDefault()
        event?.stopPropagation()
      }
    }
    try {
      const apiRes = await purchaseOrderService.getSinglePurchaseOrder(param.poNumber)
      const updatedProducts = await apiRes?.data?.products
      const PoProducts = productsSku?.map((item) => {
        const metricSize = (item?.measurements[0]?.length * item?.measurements[0]?.width) / 10000
        const validateQty = validatedQty(item?.id, updatedProducts)
        return {
          name: item?.sku,
          vendor_ref_number: item?.shipping[0].vendorReferenceNumber,
          total_sqm: metricSize * validateQty,
          price_unit: item?.shipping[0].fobCostPerPc,
          product_qty: validateQty
        }
      })
      if (!purchaseOrderDetail?.poNumber || !purchaseOrderDetail?.supplier) {
        return
      }
      let ProductValues = {
        po_name: purchaseOrderDetail?.poNumber,
        currency_name: 'USD',
        vendor_details: [
          {
            company_type: 'person',
            name: odooVendors.companyName,
            street_name: odooVendors.street,
            street_number: odooVendors.street,
            street_number_2: odooVendors.street,
            street_2: odooVendors.street,
            email: odooVendors?.email,
            city: odooVendors.city,
            state_id: odooVendors.state,
            zip: odooVendors.zip,
            country: odooVendors.country,
            odoo_vendor_id: odooVendors.oddoVendorId,
            currency_name: 'USD'
          }
        ],
        order_line: PoProducts,
        destination_warehouse: purchaseOrderDetail?.shipTo,
        order_deadline: moment(
          purchaseOrderDetail?.poDate ? purchaseOrderDetail?.poDate : purchaseOrderDetail?.order_deadline
        ).format('MM/DD/YYYY'),
        date_planned: moment(purchaseOrderDetail?.poDueDate).format('MM/DD/YYYY'),
        is_adjustment_po: 'false'
      }
      if (ProductValues?.order_line?.length) {
        await poPublishUseMutation(ProductValues)
      }
    } catch (error) {}
  }
  const validateSubmitHandlerMutationCall = useMutation(
    async (params) => {
      const { PoNumber, poSku } = params
      const response = await purchaseOrderService.UpdatePoSkuValidateQty(PoNumber, poSku)
      return response
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          updatePOStatusMutation.mutateAsync({
            PoNumber: variables?.poSku?.validationData[0]?.poId,
            status: 'Confirmed'
          }),
          createDelivery.mutateAsync(),
          await handlePoPublish()
        ])
      },
      onError: (err) => {
        setModal(false)
        setValidating(false)
        console.log(err)
      }
    }
  )
  const cancelPo = async (PoNumber) => {
    setIsCancelPoLoading(true)
    try {
      await updatePOStatusMutation.mutateAsync({ PoNumber: PoNumber, status: 'Cancelled' })
    } catch (error) {
      setIsCancelPoLoading(false)
    } finally {
      setIsCancelPoLoading(false)
    }
  }
  const fetchBaseUrl = async () => {
    try {
      if (param.poNumber) {
        let payload = {
          name: param.poNumber
        }
        const queryParams = new URLSearchParams(payload)
        const apiRes = await purchaseOrderService.DownloadPoDetail(queryParams.toString())
        if (apiRes) {
          await downloadPDF(
            apiRes?.purchase_order_report[0]?.purchase_report,
            apiRes?.purchase_order_report[0]?.po_name
          )
          showToast('Downloaded !', true)
        }
      }
    } catch (error) {
      showToast(error.data)
    } finally {
    }
  }
  const updatePOStatusMutation = useMutation(
    async (data) => {
      let payload = {
        status: data.status
      }
      return await purchaseOrderService.updatePurchaseOrderStatus(data.PoNumber, payload)
    },
    {
      onSuccess: async (response) => {
        const data = await response?.data?.data
        if (data && data.status !== 'Proposal') {
          let data_to_save = {
            user_id: userType ? data?.supplier : data?.userId,
            companyId: userType ? data?.supplierCompany : data?.companyId,
            redirect_uri: data.poNumber,
            type: 'PURCHASE_ORDER',
            title: 'New Notification Received',
            message: `POV: ${data.poNumber} ${userType ? 'Buyer' : 'Vendor'} changed the status ${data?.status} `,
            created_at: +new Date()
          }
          await purchaseOrderService.createNotification(data_to_save)
        }
        setPurchaseOrderDetail((prev) => ({ ...prev, ...data }))
        const { products = [] } = data || {}
        const { skuToFobPriceMap, productData } = products.reduce(
          (acc, product) => {
            const sku = product?.productId
            const fobPrice = parseFloat(product?.price_unit ?? 0)
            acc.skuToFobPriceMap[sku] = fobPrice
            acc.productData.push(sku)
            return acc
          },
          { skuToFobPriceMap: {}, productData: [] }
        )
        await fetchProductsOnIdsMutation.mutateAsync({
          ids: productData,
          productsData: data.products,
          skuToFobPriceMap: skuToFobPriceMap,
          purchaseOrderDetail: data
        })
        showToast(`Status updated to ${data.status}!`, true)
        setModal(false)
      },
      onError: (err) => {
        setModal(false)
        setValidating(false)
        console.log(err)

      }
    }
  )
  const validateSubmitHandler = async (validationData) => {
    const poDetails = []
    let xlsxBase64
    poDetails.push(['Purchase Order Details'])
    poDetails.push([])
    const allowedKeys = [
      'poNumber',
      'supplierName',
      'shipTo',
      'shipVia',
      'status',
      'isDispatch',
      'poDate',
      'poLoadDate',
      'ready_date',
      'poDueDate'
    ]
    const keysRow = []
    const valuesRow = []
    for (const [key, value] of Object.entries(purchaseOrderDetail)) {
      if (allowedKeys.includes(key)) {
        keysRow.push(key)
        valuesRow.push(value)
      }
    }
    poDetails.push(keysRow)
    poDetails.push(valuesRow)
    poDetails.push([])
    poDetails.push([])
    poDetails.push(['Products Details'])
    poDetails.push([])
    poDetails.push([
      'sku',
      'vendor',
      'upc',
      'width',
      'length',
      'FOB',
      'SQMeterPerPiece',
      'expectedQty',
      'proposedQty',
      'size',
      'totalFOB',
      'totalSqm',
      'validatedQty'
    ])
    await rows.forEach((order) => {
      poDetails.push([
        order.sku,
        order.vendor,
        order.upc,
        order.width,
        order.length,
        order.FOB,
        order.SQMeterPerPiece,
        order.expectedQty,
        order.proposedQty,
        order.size,
        order.totalFOB,
        order.totalSqm,
        order.validatedQty
      ])
    })

    await exportJsonToCsvCustomRows(poDetails, 'PurchaseOrders_And_Products', 'base64')
      .then((base64) => {
        xlsxBase64 = base64
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    let arr = []
    try {
      vendorData.map((item) => {
        if (item?.id == supplierData?.supplier) {
          arr.push(item)
        }
      })
      const poSku = {
        validationData: validationData,
        userId: contextObj?.userData?.user?.id,
        userEmail: contextObj?.userData?.user?.email,
        user: contextObj.userData.user.fName + ' ' + contextObj.userData.user.lName,
        poNumber: supplierData.poNumber,
        companyName: arr[0]?.companyName,
        companyId: arr[0]?.companyId,
        email: arr[0]?.email,
        fname: arr[0]?.fName,
        isValidated: true,
        xlsxBase64: xlsxBase64
      }
      setValidating(true)
      await validateSubmitHandlerMutationCall.mutateAsync({ PoNumber: validationData[0]?.poId, poSku: poSku })
      setModal(false)
      setValidating(false)
      setValidatedQty(sku, qty)
      return true
    } catch {
      setModal(false)
      setValidating(false)
    } finally {
      setModal(false)
      setValidating(false)
    }
  }
  const createDelivery = useMutation(
    async () => {
      const response = await purchaseOrderService.getSinglePurchaseOrder(param.poNumber)
      return response
    },
    {
      onSuccess: async (response) => {
        const allValidated = response?.data?.products
          .map((object) => object.validatedQty.isValidated)
          .every((isValidated) => isValidated === true)
        if (allValidated) {
          await delivery(response?.data)
        }
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )
  const delivery = async (data) => {
    const products = data.products.map((product) => {
      const matchedProduct = productsSku.find((item) => item?.id === product?.productId)
      if (matchedProduct) {
        const { qty } = product?.validatedQty
        const { id: productId, sku } = matchedProduct
        return { productId, qty, sku, qtyDone: '0' }
      }
    })
    let payload = {
      products: products,
      deliveryId: data.poNumber + 'DL' + '-' + '1',
      date: new Date(),
      status: 'waiting',
      companyId: data.companyId,
      supplier: data.supplier,
      userId: contextObj.userData.user.id,
      poNumber: data.poNumber
    }
    try {
      return await deliveryUpdate({ payload, data })
    } catch (err) {}
  }
  const { mutate: deliveryUpdate } = useMutation(
    async ({ payload, data }) => {
      const apiRes = await purchaseOrderService.createInitialDelivery(payload)
      const apiData = await apiRes?.data
      if (apiData) {
        let data_to_save = {
          user_id: userType ? apiData?.supplier : apiData?.userId,
          companyId: userType ? data?.supplierCompany : data?.companyId,
          redirect_uri: apiData.poNumber,
          type: 'PURCHASE_ORDER_DELIVERY',
          title: 'New Notification Received',
          message: `POV: ${data.poNumber} Delivery Created ${apiData.deliveryId}`,
          created_at: +new Date()
        }
        await purchaseOrderService.createNotification(data_to_save)
      }
    },
    {
      onSuccess: () => {},
      onError: () => {}
    }
  )
  const updatedColumns = columns.filter((column) => {
    if (
      purchaseOrderDetail.status !== 'Quotation' &&
      purchaseOrderDetail.status !== 'Proposal' &&
      purchaseOrderDetail.status !== 'Request For Change'
    ) {
      return column.id !== 'expectedQty' && column.id !== 'proposedQty'
    }
    return true
  })

  const EditPurchaseOrder = async (ProductValues) => {
    if (ProductValues.ready_date) {
      editPurchaseOrderUseMutation(ProductValues)
      return
    }
  }

  const { mutate: editPurchaseOrderUseMutation } = useMutation(
    async (ProductValues) => {
      const apiRes = await purchaseOrderService.updatePurchaseOrder(ProductValues)
      return await apiRes.data
    },
    {
      onSuccess: async (apiData) => {
        const data = await apiData.data
        if (data.ready_date) {
          let data_to_save = {
            user_id: userType ? data?.supplier : data?.userId,
            companyId: userType ? data?.supplierCompany : data?.companyId,
            redirect_uri: data.poNumber,
            type: 'PURCHASE_ORDER',
            title: 'New Notification Received',
            message: `POV: ${data.poNumber} ${userType ? 'Buyer' : 'Vendor'} has updated the Ready date ${moment(
              data?.ready_date
            ).format('DD-MMM-YYYY')}`,
            created_at: +new Date()
          }
          try {
            await purchaseOrderService.createNotification(data_to_save)
            setPurchaseOrderDetail((prev) => ({ ...prev, ...data }))
            await showToast('Updated Ready Date', true)
          } catch (error) {
            console.error('Error fetching updated purchase order:', error)
          }
        }
      },
      onError: (err) => {
        showToast(err?.data?.error_description)
        console.error(err || 'Something went wrong')
      }
    }
  )

  return {
    showToast,
    updatedColumns,
    EditPurchaseOrder,
    rows,
    page,
    rowsPerPage,
    isPoLoading,
    isLoading,
    purchaseOrderDetail,
    handleChangePage,
    handleChangeRowsPerPage,
    contextObj,
    sendMessage,
    messages,
    isOpen,
    setIsOpen,
    modal,
    setModal,
    pId,
    submitHandler,
    isSubmitting,
    products,
    sku,
    setNewMessage,
    newMessage,
    content,
    validateSubmitHandler,
    validating,
    updateMessage,
    fetchBaseUrl,
    cancelPo,
    isCancelPoLoading
  }
}
