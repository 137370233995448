export const activityHeadCells = [
  {
    id: 'buyer',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: true,
    width: '20%'
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    isCentered: false,
    label: 'Location',
    disableSort: true,
    hiddenOnMobile: true,
    width: '25%'
  },
  {
    id: 'timeline',
    numeric: false,
    disablePadding: false,
    isCentered: false,
    label: 'Timeline',
    disableSort: true,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'summary',
    numeric: false,
    disablePadding: false,
    isCentered: true,
    label: 'Summary',
    disableSort: true,
    hiddenOnMobile: false,
    width: '45%'
  }
]
export const warehouseHeadCells = [
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  },
  {
    id: 'warehouseShortName',
    numeric: false,
    disablePadding: false,
    label: 'Alias',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'mobile',
    numeric: false,
    disablePadding: false,
    label: 'Mobile',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]
export const projectlistHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Po Number',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'shipTo',
    numeric: false,
    disablePadding: false,
    label: 'Ship To',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'poDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'poDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Due Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'shipVia',
    numeric: false,
    disablePadding: false,
    label: 'Ship Via',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },

  {
    id: 'checkbox',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]

export const catalogueHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Po Number',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'supplierName',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'shipTo',
    numeric: false,
    disablePadding: false,
    label: 'Ship To',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'poDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'poDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Due Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'poReadyDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Ready Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'shipVia',
    numeric: false,
    disablePadding: false,
    label: 'Ship Via',
    disableSort: false,
    hiddenOnMobile: true,
    width: '9%'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]
export const projectHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Project Id',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'loadDate',
    numeric: false,
    disablePadding: false,
    label: 'Load Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'arrivalDate',
    numeric: false,
    disablePadding: false,
    label: 'Arrival Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },

  {
    id: 'bookingNumber',
    numeric: false,
    disablePadding: false,
    label: 'Booking Number',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'arrivalWarehouse',
    numeric: false,
    disablePadding: false,
    label: 'Arrival Warehouse',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'freightLine',
    numeric: false,
    disablePadding: false,
    label: 'Freight Line',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'container',
    numeric: false,
    disablePadding: false,
    label: 'Container',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'documents',
    numeric: false,
    disablePadding: false,
    label: 'Documents',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'projectStage',
    numeric: false,
    disablePadding: true,
    label: 'Stage',
    disableSort: true,
    hiddenOnMobile: true,
    width: '8%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]

export const BulkImportHeardcells = [
  {
    id: 'Details',
    numeric: false,
    disablePadding: false,
    label: 'Details',
    disableSort: true,
    hiddenOnMobile: false,
    width: '30%'
  },
  {
    id: 'Time',
    numeric: false,
    disablePadding: false,
    label: 'Imported On',
    disableSort: false,
    hiddenOnMobile: false,
    width: '25%'
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },
  {
    id: 'Products',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  }
]

export const paymentHeadCells = [
  {
    id: 'order',
    numeric: false,
    disablePadding: false,
    label: 'Order',
    disableSort: true,
    hiddenOnMobile: false,
    width: '25%'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    disableSort: true,
    hiddenOnMobile: true,
    width: '8%'
  },
  {
    id: 'plan',
    numeric: false,
    disablePadding: false,
    label: 'Plan',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'invoice',
    numeric: false,
    disablePadding: false,
    label: 'Invoice',
    isCentered: true,
    disableSort: true,
    hiddenOnMobile: true,
    width: '12%',
    tooltip: true
  }
]
export const tableHeadCells = [
  {
    id: 'PoNumber',
    numeric: false,
    label: 'PO Number'
  },

  {
    id: 'Sku',
    numeric: false,
    label: 'SKU'
  },
  {
    id: 'Vendor',
    numeric: false,
    label: 'Vendor'
  },
  {
    id: 'ValidatedQuantity',
    numeric: true,
    label: 'Quantity'
  },
  {
    id: 'ReadyQuantity',
    numeric: true,
    label: 'Ready Quantity'
  },
  {
    id: 'DeliverdQuantity',
    numeric: true,
    label: 'Deliverd Quantity'
  },
  {
    id: 'PendingQuantity',
    numeric: true,
    label: 'Pending Quantity'
  },
 
  {
    id: 'Warehouse',
    numeric: false,
    label: 'Warehouse'
  },
 
]
