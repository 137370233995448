import React, { useState, useMemo } from 'react'
// useRef
import {
  Grid,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  CircularProgress,
  Collapse
} from '@mui/material'
import PropTypes from 'prop-types'
import { calculatePercentageDifference } from 'shared/util/helper'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { sortList } from 'shared/util/helper'

const SalesReportShopTable = (props) => {
  const { shopOverallData = [], loading , currentSelectedYear , previousSelectedYear} = props
  const handleRowClick = (month) => {
    setExpandedMonth((prevMonth) => (prevMonth === month ? null : month))
  }
  const [sortConfig, setSortConfig] = useState({ type: 'SKU', order: 'asc' })
  const [filteredData, setFilteredData] = useState()

  const getIconStyle = (type, order) => ({
    color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
    cursor: 'pointer',
    marginLeft: '1px'
  })
  const sortedData = useMemo(() => {
    return !shopOverallData.monthlySales
      ? []
      : shopOverallData.monthlySales.map((month) => {
          return {
            ...month,
            sku_sales: Array.isArray(month.sku_sales)
              ? sortList([...month?.sku_sales], sortConfig?.type, sortConfig?.order)
              : []
          }
        })
  }, [shopOverallData.monthlySales, sortConfig])

  useMemo(() => {
    return setFilteredData(sortedData)
  }, [sortedData])
  const [expandedMonth, setExpandedMonth] = useState(null)

  const handleSearch = (month, search) => {
    const value = search.target.value.trim()
    if (value === '' || value === undefined) {
      setFilteredData(sortedData)
      return
    }
    const result = sortedData.map((item) => {
      if (item.month === month) {
        const filteredSales = item.sku_sales.filter((element) =>
          element.sku.toUpperCase().includes(value.toUpperCase())
        )
        return { ...item, sku_sales: filteredSales }
      }
      return item
    })
    setFilteredData(result)
  }

  const handleSort = async (type) => {
    const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
    setSortConfig({ type, order: newOrder })
  }
  const totalQty2024 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.qty_2024 || 0), 0)
  const totalUntaxed2024 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.untaxed_2024 || 0), 0)
  const totalQty2023 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.qty_2023 || 0), 0)
  const totalUntaxed2023 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.untaxed_2023 || 0), 0)

  return (
    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Months</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>QTY Ordered {previousSelectedYear}</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>QTY Ordered {currentSelectedYear}</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Variance(QTY)</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Untaxed Total {previousSelectedYear}</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Untaxed Total {currentSelectedYear}</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Variance(Untaxed)</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(filteredData) && filteredData.length ? (
              filteredData.map((item, index) => (
                <React.Fragment key={index}>
                  {/* Main row */}
                  <TableRow onClick={() => handleRowClick(item?.month)} style={{ cursor: 'pointer' }}>
                    <TableCell sx={{ textAlign: 'center' }}>{item?.month || '--'}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2023 || 0}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2024 || 0}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: calculatePercentageDifference(item?.qty_2024, item?.qty_2023) < 0 ? 'red' : 'green'
                      }}
                    >
                      {Math.abs(
                        calculatePercentageDifference(item?.qty_2024, item?.qty_2023)
                          .toFixed(2)
                          .replace(/[.,]00$/, '')
                      )}
                      %
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item?.untaxed_2023 ? item.untaxed_2023.toFixed(2) : 0}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item?.untaxed_2024 ? item.untaxed_2024.toFixed(2) : 0}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: calculatePercentageDifference(item?.qty_2024, item?.qty_2023) < 0 ? 'red' : 'green'
                      }}
                    >
                      {Math.abs(
                        calculatePercentageDifference(item?.untaxed_2024, item?.untaxed_2023)
                          .toFixed(2)
                          .replace(/[.,]00$/, '')
                      )}
                      %
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={7} sx={{ padding: 0, border: 'none' }}>
                      <Collapse in={expandedMonth === item?.month} timeout='auto' unmountOnExit>
                        <div
                          style={{
                            maxHeight: '450px',
                            overflowY: 'auto',
                            padding: '15px',
                            paddingTop: '0px',
                            backgroundColor: '#fafafa'
                          }}
                        >
                          <Table size='small'>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>
                                  <strong>Sr no.</strong>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>
                                  <strong
                                    style={{ marginLeft: '11rem' }}
                                    onClick={() => {
                                      handleSort('SKU')
                                    }}
                                  >
                                    SKU
                                    <FaArrowUpLong style={getIconStyle('SKU', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('SKU', 'desc')} />
                                  </strong>
                                  <input
                                    className='ms-3'
                                    onChange={(e) => {
                                      handleSearch(item.month, e)
                                    }}
                                  ></input>
                                </TableCell>

                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>
                                  <strong
                                    onClick={() => {
                                      handleSort('Qty')
                                    }}
                                  >
                                    Qty
                                    <FaArrowUpLong style={getIconStyle('Qty', 'asc')} />
                                    <FaArrowDownLong style={getIconStyle('Qty', 'desc')} className='ms-0' />
                                  </strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.sku_sales?.length ? (
                                item.sku_sales.map((skuItem, skuIndex) => (
                                  <TableRow key={skuIndex}>
                                    <TableCell sx={{ textAlign: 'center' }}>{skuIndex + 1}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{skuItem?.sku || '--'}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{skuItem?.qty || 0}</TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                                    No data found
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : !loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    <p className='text-center'>No Records Found..</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {shopOverallData?.monthlySales?.length > 0 && (
              <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}>
                  <strong>Total</strong>
                </TableCell>

                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalQty2023}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalQty2024.toFixed(2)}</TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: calculatePercentageDifference(totalQty2024, totalQty2023) < 0 ? 'red' : 'green'
                  }}
                >
                  {calculatePercentageDifference(totalQty2024, totalQty2023)
                    .toFixed(2)
                    .replace(/[.,]00$/, '')}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalUntaxed2023.toFixed(2)}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalUntaxed2024.toFixed(2)}</TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: calculatePercentageDifference(totalUntaxed2024, totalUntaxed2023) < 0 ? 'red' : 'green'
                  }}
                >
                  {calculatePercentageDifference(totalUntaxed2024, totalUntaxed2023)
                    .toFixed(2)
                    .replace(/[.,]00$/, '')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {loading && (
        <div className='d-flex mt-4 justify-content-center align-items-center'>
          <CircularProgress />
        </div>
      )}
    </Grid>
  )
}

SalesReportShopTable.propTypes = {
  shopOverallData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  previousSelectedYear:PropTypes.number.isRequired,
  currentSelectedYear: PropTypes.number.isRequired
}

export default SalesReportShopTable
