import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { styled } from '@mui/system'

const StyledTable = styled(Table)({
  borderCollapse: 'collapse',
  width: '100%',
  '& th, & td': {
    border: '1px solid #ddd',
    padding: '8px'
  },
  '& th': {
    paddingTop: '12px',
    paddingBottom: '12px',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    color: 'black'
  },
  '& td': {
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }
})

const ShopSkuDataComponent = (props) => {
  const {
    shops,
    shopCountryData,
    handleClick,
    clickedRows,
    isShopCountryDataAvailable,
    formatDecimal,
    shopsSku,
    lastYear,
    currentYear
  } = props
  return (
    <>
      <Grid container direction='row' justifyContent='space-between'>
        <span className='pb-3'>Shop Data</span>
      </Grid>

      <Grid container direction='row' justifyContent='space-between'>
        <Grid item md={12}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Shop</TableCell>
                <TableCell>Shipment Country</TableCell>
                <TableCell>Untaxed {currentYear}</TableCell>
                <TableCell>Qty Ordered {currentYear}</TableCell>
                <TableCell>Untaxed {lastYear}</TableCell>
                <TableCell>Qty Ordered {lastYear}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shops?.period_data?.map((shopData, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    onClick={() => handleClick(index)}
                    style={{
                      backgroundColor:
                        clickedRows.includes(index) || isShopCountryDataAvailable(shopData.shop)
                          ? '#f9f9f9'
                          : 'inherit',
                      fontWeight: isShopCountryDataAvailable(shopData.shop) ? 'bold' : 'normal'
                    }}
                  >
                    <TableCell>{shopData.shop}</TableCell>
                    <TableCell>{shopData.shipment_country || '-'}</TableCell>
                    <TableCell>{formatDecimal(shopData.untaxed_2024) || '-'}</TableCell>
                    <TableCell>{shopData.qty_ordered_2024 || '-'}</TableCell>
                    <TableCell>{formatDecimal(shopData.untaxed_2023) || '-'}</TableCell>
                    <TableCell>{shopData.qty_ordered_2023 || '-'}</TableCell>
                  </TableRow>
                  {(clickedRows.includes(index) || isShopCountryDataAvailable(shopData.shop)) && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <StyledTable>
                          <TableHead>
                            <TableRow>
                              <TableCell>Shipment Country</TableCell>
                              <TableCell>Untaxed {currentYear}</TableCell>
                              <TableCell>Qty Ordered {currentYear}</TableCell>
                              <TableCell>Untaxed {lastYear}</TableCell>
                              <TableCell>Qty Ordered {lastYear}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {shopCountryData?.period_data
                              ?.filter((countryData) => countryData?.shop === shopData?.shop)
                              .map((countryData, subIndex) => (
                                <TableRow key={`${index}-${subIndex}`} style={{ backgroundColor: '#f9f9f9' }}>
                                  <TableCell>{countryData.shipment_country}</TableCell>
                                  <TableCell>{formatDecimal(countryData.untaxed_2024) || '-'}</TableCell>
                                  <TableCell>{countryData.qty_ordered_2024 || '-'}</TableCell>
                                  <TableCell>{formatDecimal(countryData.untaxed_2023) || '-'}</TableCell>
                                  <TableCell>{countryData.qty_ordered_2023 || '-'}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </StyledTable>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </Grid>
      </Grid>

      <Grid container direction='row' justifyContent='space-between'>
        <span className='pb-3 pt-3'>Sku Data</span>
      </Grid>

      <Grid container direction='row' justifyContent='space-between'>
        <Grid item md={12}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Sku (clickable)</TableCell>
                <TableCell>Untaxed {currentYear}</TableCell>
                <TableCell>Qty Ordered {currentYear}</TableCell>
                <TableCell>Untaxed {lastYear}</TableCell>
                <TableCell>Qty Ordered {lastYear}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shopsSku?.period_data?.map((shopData, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <Tooltip title={'Clickable SKU to show Images'}>
                      <TableCell>
                        <a
                          href={
                            shopData?.flat_image ||
                            `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: 'blue' }}
                        >
                          {shopData.sku}
                        </a>
                      </TableCell>
                    </Tooltip>
                    <TableCell>{formatDecimal(shopData.untaxed_2024) || '-'}</TableCell>
                    <TableCell>{shopData.qty_ordered_2024 || '-'}</TableCell>
                    <TableCell>{formatDecimal(shopData.untaxed_2023) || '-'}</TableCell>
                    <TableCell>{shopData.qty_ordered_2023 || '-'}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </Grid>
      </Grid>
    </>
  )
}

ShopSkuDataComponent.propTypes = {
  shops: PropTypes.shape({
    period_data: PropTypes.arrayOf(
      PropTypes.shape({
        shop: PropTypes.string.isRequired,
        shipment_country: PropTypes.string,
        untaxed_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        untaxed_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ).isRequired
  }).isRequired,
  shopCountryData: PropTypes.shape({
    period_data: PropTypes.arrayOf(
      PropTypes.shape({
        shop: PropTypes.string.isRequired,
        shipment_country: PropTypes.string.isRequired,
        untaxed_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        untaxed_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ).isRequired
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  clickedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  isShopCountryDataAvailable: PropTypes.func.isRequired,
  formatDecimal: PropTypes.func.isRequired,
  lastYear: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  shopsSku: PropTypes.shape({
    period_data: PropTypes.arrayOf(
      PropTypes.shape({
        flat_image: PropTypes.string.isRequired,
        sku: PropTypes.string.isRequired,
        untaxed_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        untaxed_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qty_ordered_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ).isRequired
  }).isRequired
}

export default ShopSkuDataComponent
