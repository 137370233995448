import React, { } from 'react'
import { CSidebar, CSidebarNav, CSidebarBrand } from '@coreui/react'
import PropTypes from 'prop-types'
import { AppSidebarNav } from './AppSidebarNav'
import navigation from 'routes/SidebarNav'
import Icon from 'assets/images/small-logo.png'
import image from 'assets/images/large-logo.png'
import 'simplebar/dist/simplebar.min.css'
import './AppSideBar.css'

const AppSidebar = (props) => {
  return (
    <CSidebar
      position='fixed'
      narrow={props.show}

    >
      <CSidebarBrand className='d-none d-md-flex' to='/'>
        <img
          src={image}
          height='90%'
          width='90%'
          alt='POM'
          className='sidebar-brand-full'
        />
        <img src={Icon} height={50} alt='POM' className='sidebar-brand-narrow' />
      </CSidebarBrand>
      <CSidebarNav>
          <AppSidebarNav items={navigation} />
      </CSidebarNav>
    </CSidebar>
  )
}

AppSidebar.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
}

export default React.memo(AppSidebar)
