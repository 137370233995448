import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import Button from 'components/formElements/Button'

function PoCancel(props) {
  const submitHandler = () => {
    props.cancelPo(props?.poDetails?._id)
  }
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' className='mb-4'>
      <Grid item sm={10}>
        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Are you sure to cancel this PO?</h5>
      </Grid>

      <Grid item sm={12} className='mt-3'>
        <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
          <Grid item>
            <Button variant='outlined' onClick={props.cancelAction} color='secondary' label='No' />
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              //disabled={!formState.isValid}
              onClick={submitHandler}
              isLoading={props.isCancelPoLoading}
              color='warning'
              label='Yes'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
PoCancel.propTypes = {
  cancelPo: PropTypes.func,
  pId: PropTypes.string,
  poDetails: PropTypes.object,
  cancelAction: PropTypes.func,
  isCancelPoLoading: PropTypes.bool
}

export default PoCancel
