import { UserContext } from 'context/AuthContext/UserContext'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import purchaseOrderService from 'shared/services/purchase-order-service'

export const useSalesReportHooks = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)
  const { showToast } = useContext(UserContext)
  const [stockAlertPage, setStockAlertPage] = useState(1)
  const [saleTrendPage, setSaleTrendPage] = useState(0)
  const [sortConfig, setSortConfig] = useState({ type: 'sku', order: 'asc' })
  const [stockAlertData, setStockAlertData] = useState({
    data: [],
    total_count: 0
  })
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const toggleDrawer = (newOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsOpenDrawer(newOpen)
  }
  const onChangeRouter = (key, value) => {
    const Param = new URLSearchParams(location.search)
    Param.set(key, value)
    if (!value) {
      Param.delete(key)
    }
    navigate({ search: Param.toString() })
  }
  const onChangeFilter = (name, type) => {
    let dateChanged = new Date(type)
    if (type) {
      newParam.set(name, dateChanged)
    } else {
      newParam.delete(name)
      newParam.delete('end_date')
      newParam.delete('sku_end_date')
    }
    navigate({ search: newParam.toString() })
  }
  const monthsOptions = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
  ]
  const getSelectedMonths = (type) => {
    if (type === 'overall_month') {
      const searchParams = new URLSearchParams(location.search)
      const months = searchParams.get('overall_month')
      return months ? months.split(',') : []
    } else {
      const searchParams = new URLSearchParams(location.search)
      const months = searchParams.get('months')
      return months ? months.split(',') : []
    }
  }
  const onChangeRouters = (key, value) => {
    const Param = new URLSearchParams(location.search)
    if (value.length === 0) {
      Param.delete(key)
    } else {
      Param.set(key, value.join(','))
    }
    navigate({ search: Param.toString() })
  }
  const handleMonthsChange = (event, type) => {
    const value = event.target.value
    if (type === 'overall_Month') {
      if (value.includes('all')) {
        const allMonths = monthsOptions.map((month) => month.value)
        onChangeRouters('overall_month', allMonths)
      } else {
        const newSelectedMonths = value.filter((month) => month !== 'all')
        onChangeRouters('overall_month', newSelectedMonths)
      }
    } else {
      if (value.includes('all')) {
        const allMonths = monthsOptions.map((month) => month.value)
        onChangeRouters('months', allMonths)
      } else {
        const newSelectedMonths = value.filter((month) => month !== 'all')
        onChangeRouters('months', newSelectedMonths)
      }
    }
  }
  const { data: warehouseData } = useQuery(
    ['getWarehouse'],
    async () => {
      const response = await purchaseOrderService.getWarehouseData()
      const sortedResponse = await response?.data
        .sort((a, b) => a?.warehouse.localeCompare(b?.warehouse))
        .map((item) => item?.warehouse)
      return sortedResponse
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  const { data: uniqueVendors } = useQuery(
    ['getUniqueVendors'],
    async () => {
      const response = await purchaseOrderService.getUniqueVendors()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  const { data: shipmentData } = useQuery(
    ['getShipment'],
    async () => {
      const apiRes = await purchaseOrderService.getShipmentData()
      const sortedResponse = await apiRes?.data
        .sort((a, b) => a?.shipment_country.localeCompare(b?.shipment_country))
        .map((item) => item?.shipment_country)
      return sortedResponse
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  const { data: shopData } = useQuery(
    ['getShop'],
    async () => {
      const response = await purchaseOrderService.getShopData()
      const sortedResponse = await response?.data
        .sort((a, b) => a?.shop.localeCompare(b?.shop))
        .map((item) => item?.shop)
      return sortedResponse
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  const initilizeSalesReportList = async (newParam) => {
    let query = {}
    if (newParam.get('selected_year')) {
      query.selected_year = newParam.get('selected_year')
    } else {
      query.selected_year = new Date().getFullYear()
    }
    if (newParam.get('warehouse')) query.warehouse = newParam.get('warehouse')
    if (newParam.get('sku')) query.search = newParam.get('sku')
    if (newParam.get('shops')) query.shop = newParam.get('shops')
    if (newParam.get('shop_type')) query.shop_type = newParam.get('shop_type')
    if (newParam.get('status')) query.status = newParam.get('status')
    if (newParam.get('vendor')) query.vendor = newParam.get('vendor')
    if (newParam.get('commerce_type')) query.commerce_type = newParam.get('commerce_type')
    if (newParam.get('shipment_country')) query.shipment_country = newParam.get('shipment_country')
    if (newParam.get('months')) query.months = newParam.get('months')
    if (newParam.get('month_quarter')) query.month_quarter = newParam.get('month_quarter')
    const queryParams = new URLSearchParams(query)
    const apiRes = await purchaseOrderService.getSalesReportData(queryParams.toString())
    return apiRes.data
  }
  const { data: salesReportData, isLoading } = useQuery(
    [
      'getSalesReport',
      newParam.get('selected_year'),
      newParam.get('months'),
      newParam.get('warehouse'),
      newParam.get('sku'),
      newParam.get('shipment_country'),
      newParam.get('shop_type'),
      newParam.get('status'),
      newParam.get('vendor'),
      newParam.get('commerce_type'),
      newParam.get('month_quarter'),
      newParam.get('shops')
    ],
    () => initilizeSalesReportList(newParam),
    { refetchOnWindowFocus: false }
  )
  const initilizeShopOveralltList = async (newParam) => {
    let query = {}
    if (newParam.get('selected_year')) {
      query.selected_year = newParam.get('selected_year')
    } else {
      query.selected_year = new Date().getFullYear()
    }
    if (newParam.get('warehouse_overall')) query.warehouse = newParam.get('warehouse_overall')
    if (newParam.get('sku_overall')) query.search = newParam.get('sku_overall')
    if (newParam.get('shops_overall')) query.shop = newParam.get('shops_overall')
    if (newParam.get('shop_type_overall')) query.shop_type = newParam.get('shop_type_overall')
    if (newParam.get('status_overall')) query.status = newParam.get('status_overall')
    if (newParam.get('vendor_overall')) query.vendor = newParam.get('vendor_overall')
    if (newParam.get('commerce_type_overall')) query.commerce_type = newParam.get('commerce_type_overall')
    if (newParam.get('shipment_country_overall')) query.shipment_country = newParam.get('shipment_country_overall')
    if (newParam.get('overall_month')) query.months = newParam.get('overall_month')
    const queryParams = new URLSearchParams(query)
    const apiRes = await purchaseOrderService.getShopOverallData(queryParams.toString())
    return apiRes.data
  }
  const { data: shopOverallData, isLoading: shopOverallLoading } = useQuery(
    [
      'getShopOverall',
      newParam.get('selected_year'),
      newParam.get('overall_month'),
      newParam.get('warehouse_overall'),
      newParam.get('sku_overall'),
      newParam.get('shops_overall'),
      newParam.get('shop_type_overall'),
      newParam.get('status_overall'),
      newParam.get('vendor_overall'),
      newParam.get('commerce_type_overall'),
      newParam.get('shipment_country_overall')
    ],
    () => initilizeShopOveralltList(newParam),
    { refetchOnWindowFocus: false }
  )

  return {
    toggleDrawer,
    isOpenDrawer,
    setIsOpenDrawer,
    newParam,
    navigate,
    warehouseData,
    shipmentData,
    shopData,
    saleTrendPage,
    setSaleTrendPage,
    stockAlertPage,
    stockAlertData,
    setStockAlertData,
    setStockAlertPage,
    sortConfig,
    setSortConfig,
    onChangeRouter,
    onChangeFilter,
    showToast,
    salesReportData,
    isLoading,
    shopOverallData,
    shopOverallLoading,
    uniqueVendors,
    getSelectedMonths,
    handleMonthsChange,
    monthsOptions,
  }
}
