import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import SkeletonRow from 'components/common/SkeletonRow'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const TrendsSalesTable = React.forwardRef((props, ref) => {
    const { sortBy, trendsData = [], saleTrendPerPage, setSaleTrendPage, saleTrendPage, loading , newParam } = props
    const ExportDataLabelYear =  newParam?.get('start_date') ? new Date(newParam?.get('start_date')).getFullYear() : new Date().getFullYear()
    const [sortConfig, setSortConfig] = useState({ type: 'sales_percentage', order: 'asc' })
    const tableContainerRef = useRef(null)
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const getIconStyle = (type, order) => ({
        color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
        cursor: 'pointer',
        marginLeft: '1px',
    })
    const getMaxPeriods = (data) => {
        const uniquePeriods = new Set()
        data.forEach(item => {
            Object.keys(item.sales).forEach(period => {
                uniquePeriods.add(period)
            })
        })
        const periodsArray = Array.from(uniquePeriods)
        return sortPeriods(periodsArray)
    }
    const sortPeriods = (periodsArray) => {
        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
        const quarterNames = ["Q1", "Q2", "Q3", "Q4"]
        return periodsArray.sort((a, b) => {
            if (a.startsWith('week') && b.startsWith('week')) {
                return parseInt(a.slice(4)) - parseInt(b.slice(4))
            }
            if (monthNames.includes(a) && monthNames.includes(b)) {
                return monthNames.indexOf(a) - monthNames.indexOf(b)
            }
            if (quarterNames.includes(a) && quarterNames.includes(b)) {
                return quarterNames.indexOf(a) - quarterNames.indexOf(b)
            }
            return 0
        })
    }
    const periodsArray = getMaxPeriods(trendsData)
    const generateTableHeaders = (periodsArray) => {
        const firstRow = (
            <TableRow>
                <TableCell><strong>{sortBy}</strong></TableCell>
                {/* <TableCell><strong>Image</strong></TableCell> */}
                <TableCell align="center" colSpan={periodsArray.length}><strong >Sales Data</strong></TableCell>
                <TableCell style={{ minWidth: 180 }}>
                    <strong onClick={() => handleSort('shops_total_unit_current')}>Sales Trends
                        <FaArrowUpLong style={getIconStyle('shops_total_unit_current', 'asc')} />
                        <FaArrowDownLong className='ms-0' style={getIconStyle('shops_total_unit_current', 'desc')} />
                    </strong>
                </TableCell>
                <TableCell style={{ minWidth: 160 }}>
                    <strong onClick={() => handleSort('average_sales')}>Average Sales
                        <FaArrowUpLong style={getIconStyle('average_sales', 'asc')} />
                        <FaArrowDownLong className='ms-0' style={getIconStyle('average_sales', 'desc')} />
                    </strong>
                </TableCell>
                <TableCell ><strong onClick={() => handleSort('cv_qty_ordered')}>CV
                    <FaArrowUpLong style={getIconStyle('cv_qty_ordered', 'asc')} />
                    <FaArrowDownLong className='ms-0' style={getIconStyle('cv_qty_ordered', 'desc')} />
                </strong></TableCell>
            </TableRow>
        )
        const secondRow = (
            <TableRow>
                <TableCell align="center" colSpan={1}></TableCell>
                {periodsArray.map((period) => (
                    <TableCell key={period} ><strong>{period}</strong></TableCell>
                ))}
                <TableCell align="center" colSpan={3}></TableCell>
            </TableRow>
        )
        return [firstRow, secondRow]
    }
    const tableHeaders = generateTableHeaders(periodsArray)
    const aggregateData = (skuData) => {
        const aggregatedData = skuData.reduce((acc, item) => {
            const skuPrefix = item.sku.split('-')[0]
            if (!acc[skuPrefix]) {
                acc[skuPrefix] = {
                    flat_image: item.flat_image,
                    sku: skuPrefix,
                    sales: {},
                    shops_total_unit_current: 0,
                    shop_total_unit_previous: 0,
                    mean_qty_ordered: 0,
                    stddev_qty_ordered: 0,
                    cv_qty_ordered: 0,
                    average_sales: 0,
                    sales_percentage: 0,
                }
            }
            Object.keys(item.sales).forEach((period) => {
                if (!acc[skuPrefix].sales[period]) {
                    acc[skuPrefix].sales[period] = {
                        current: { untaxed_total: 0, qty_ordered: 0 },
                        previous: { untaxed_total: 0, qty_ordered: 0 },
                        untaxed_total_diff: 0,
                        qty_ordered_diff: 0
                    }
                }
                acc[skuPrefix].sales[period].current.untaxed_total += item?.sales[period].current?.untaxed_total ?? 0
                acc[skuPrefix].sales[period].current.qty_ordered += item?.sales[period].current?.qty_ordered ?? 0
                acc[skuPrefix].sales[period].previous.untaxed_total += item?.sales[period].previous?.untaxed_total ?? 0
                acc[skuPrefix].sales[period].previous.qty_ordered += item?.sales[period].previous?.qty_ordered ?? 0
                acc[skuPrefix].sales[period].untaxed_total_diff += item?.sales[period].untaxed_total_diff ?? 0
                acc[skuPrefix].sales[period].qty_ordered_diff += item?.sales[period].qty_ordered_diff ?? 0
            })
            acc[skuPrefix].shops_total_unit_current += item.shops_total_unit_current ?? 0
            acc[skuPrefix].shop_total_unit_previous += item.shop_total_unit_previous ?? 0
            acc[skuPrefix].mean_qty_ordered += item.mean_qty_ordered ?? 0
            acc[skuPrefix].stddev_qty_ordered += item.stddev_qty_ordered ?? 0
            acc[skuPrefix].cv_qty_ordered += item.cv_qty_ordered ?? 0
            acc[skuPrefix].average_sales += item.average_sales ?? 0
            acc[skuPrefix].sales_percentage += item.sales_percentage ?? 0
            return acc
        }, {})
        return Object.values(aggregatedData)
    }
    const aggregatedData = useMemo(() => {
        if (sortBy === 'Design') {
            return aggregateData(trendsData || [])
        }
        return trendsData || []
    }, [trendsData, sortBy])
    const sortData = (trendsData, type, order) => {
        return [...trendsData].sort((key1, key2) => {
            let Key1Value = key1[type.toLowerCase()]
            let Key2Value = key2[type.toLowerCase()]
            if (Key1Value === undefined || Key1Value === null) Key1Value = 0
            if (Key2Value === undefined || Key2Value === null) Key2Value = 0
            return order === 'asc' ? Key1Value - Key2Value : Key2Value - Key1Value
        })
    }
    const sortedData = useMemo(() => sortData([...aggregatedData], sortConfig.type, sortConfig.order), [aggregatedData, sortConfig])
    const paginatedData = useMemo(() => {
        const start = 0
        const end = (saleTrendPage + 1) * saleTrendPerPage
        return sortedData.slice(start, end)

    }, [sortedData, saleTrendPage, saleTrendPerPage])
    const loadMoreData = useCallback(() => {
        setSaleTrendPage(prevPage => {
            return prevPage + 1
        })
    }, [setSaleTrendPage])
    const handleSort = async (type) => {
        const container = tableContainerRef.current
        if (container) {
            await container.scrollTo({ top: 0, behavior: 'smooth' })
        }
        const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
        setSortConfig({ type, order: newOrder })
    }
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 10 && !allDataLoaded && !loading) {
            loadMoreData()
        }
    }
    const prepareDataForExport = () => {
        const periodsWithLabels = periodsArray.flatMap(period => [`${period} ${ExportDataLabelYear}`, `${period} ${ExportDataLabelYear - 1}`])
        const headers = ["SKU", ...periodsWithLabels, "Sales Trends", "Average Sales", "CV"]
        const rows = [...sortedData].map(item => {
            const salesData = periodsArray.flatMap(period => {
                const currentQty = item?.sales[period]?.current?.qty_ordered || 0
                const previousQty = item?.sales[period]?.previous?.qty_ordered || 0
                return [currentQty, previousQty]
            })
            return [
                item.sku,
                ...salesData,
                item?.shops_total_unit_current,
                item?.average_sales,
                item?.cv_qty_ordered
            ]
        })
        return [headers, ...rows]
    }
    // Function to export to XLSX
    useImperativeHandle(ref, () => ({
        exportToXlsx: () => {
            const data = prepareDataForExport()
            const ws = XLSX.utils.aoa_to_sheet(data)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Trends Sales Data')
            XLSX.writeFile(wb, 'Sales_Trends_Alert.xlsx')
        }
    }))
    useEffect(() => {
        if (sortedData.length) {
            if (paginatedData.length >= sortedData.length) {
                setAllDataLoaded(true)
            } else {
                setAllDataLoaded(false)
            }
        }
    }, [paginatedData.length, sortedData.length])
    useEffect(() => {
        const container = tableContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll, true)
            return () => {
                container.removeEventListener('scroll', handleScroll, true)
            }
        }
    }, [allDataLoaded, saleTrendPage, loading])
    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}  >
            <TableContainer className='scroll-box' ref={tableContainerRef}>
                <Table stickyHeader >
                    <TableHead>
                        {tableHeaders.map((headerRow, index) => (
                            <React.Fragment key={index}>{headerRow}</React.Fragment>
                        ))}
                    </TableHead>
                    <TableBody>
                        {loading ? (Array.from({ length: 10 }, (_, index) => <SkeletonRow key={index} cols={5} />)
                        ) : (Array.isArray(paginatedData) && paginatedData.length ? (paginatedData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Tooltip
                                        title={<img src={item?.flat_image ? item?.flat_image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`} alt="Product" width="200px" />}
                                        arrow
                                        placement="right"
                                        PopperProps={{
                                            style: { marginLeft: '10px' }
                                        }}
                                    >
                                        {item?.sku ? item?.sku : '--'}
                                    </Tooltip>
                                </TableCell>
                                {periodsArray.map((period) => (
                                    <>
                                        <TableCell key={period}>
                                            {item.sales[period] ? (
                                                <>
                                                    <span>{item?.sales[period]?.current?.qty_ordered ?? 0}</span>
                                                    <p> ({item.sales[period].qty_ordered_diff ? `${Math.sign(item.sales[period].qty_ordered_diff) === 1 ? (item.sales[period].qty_ordered_diff.toFixed(2)) : Math.abs(item?.sales[period]?.qty_ordered_diff).toFixed(2)} %` : 0}) {Math.sign(item.sales[period].qty_ordered_diff) === 1 ? <TiArrowSortedUp fontSize={'1.2rem'} color='#249968' /> : <TiArrowSortedDown fontSize={'1.2rem'} color='#ca4646' />}</p>
                                                </>
                                            ) : (0)}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell ><span>{item?.shops_total_unit_current ?? 0}</span><p>{item?.sales_percentage ? `(${Math.abs(item?.sales_percentage.toFixed(2))} %)` : ""} {Math.sign(item?.sales_percentage) === 1 ? <TiArrowSortedUp fontSize={'1.2rem'} color='#249968' /> : <TiArrowSortedDown fontSize={'1.2rem'} color='#ca4646' />}</p></TableCell>
                                <TableCell >{item?.average_sales ? `${item?.average_sales.toFixed(2)}` : 0}</TableCell>
                                <TableCell style={{ minWidth: 85 }} >{item?.cv_qty_ordered ? `${item?.cv_qty_ordered.toFixed(2)}` : 0}</TableCell>
                            </TableRow>
                        ))
                        ) : (
                            <TableRow >
                                <TableCell colSpan={6}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
                                        <p className='text-center'>No Records Found...</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
})
TrendsSalesTable.displayName = 'TrendsSalesTable'
TrendsSalesTable.propTypes = {
    trendsData: PropTypes.array,
    sortBy: PropTypes.string.isRequired,
    setSaleTrendPage: PropTypes.func.isRequired,
    saleTrendPage: PropTypes.number.isRequired,
    calcuateData: PropTypes.object.isRequired,
    saleTrendPerPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    newParam:PropTypes.string.isRequired
}

export default TrendsSalesTable
