import React, { useContext, useState, useEffect } from 'react'
import { CCol, CRow, CFormLabel } from '@coreui/react'
import {
  Grid,
  Skeleton,
  Modal,
  Box,
  TextField,
  Button as MuiButton,
  CircularProgress,
  Tooltip,
  FormControl,
  Autocomplete,
  Typography,
  Select,
  MenuItem
} from '@mui/material'
import { useProjectsDetailsHooks } from './useProjectsDetailsHook'
import BasicTable from 'components/uiElements/table/BasicTable'
import { Button as ProductButton } from '@mui/material'
import { HiViewGridAdd } from 'react-icons/hi'
import { AiOutlineAppstoreAdd, AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { CiExport, CiImport } from 'react-icons/ci'
import { Link, useParams } from 'react-router-dom'
import { exportJsonToCsvCustomRows, getDate } from 'shared/util/helper'
import ChatBox from 'components/chat/chatBox'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { UserContext } from 'context/AuthContext/UserContext'
import uploadServices from 'shared/services/upload.service'
import purchaseOrderService from 'shared/services/purchase-order-service'
import DocumentsTable from 'components/uiElements/table/ProjectDocumentsTable'

const ProjectsDetails = (props) => {
  const { userData, showToast } = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [file, setFile] = useState(null)
  const [name, setName] = useState('')
  const [isLoadings, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [dynamicOptions, setDynamicOptions] = useState([])
  const [fileName, setFileName] = useState('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)

  const options = [
    { label: 'Waiting', value: 'Waiting' },
    { label: 'Loading', value: 'Loading' },
    { label: 'In Transit', value: 'In Transit' },
    { label: 'Arrived', value: 'Arrived' },
    { label: 'Delivered', value: 'Delivered' }
  ]

  const {
    columns,
    rows,
    page,
    rowsPerPage,
    isPoLoading,
    projectDetail,
    values,
    setValues,
    handleChangePage,
    handleChangeRowsPerPage,
    onStageChange,
    contextObj,
    isOpen,
    setIsOpen,
    sendMessage,
    messages,
    setNewMessage,
    newMessage,
    isProLoading,
    setProjectDetail,
    downloadProjectDeliveryAndOrders,
    projectDelivery
  } = useProjectsDetailsHooks(props)

  const param = useParams()
  let breadCrumbPath = [
    { name: 'Projects', url: `/projects`, active: 'not-allowed' },
    { name: 'Project-Details', url: `/projectsdetails/${param.projectId}`, active: 'not-allowed' }
  ]

  const handleOpen = (document) => {
    setSelectedDocument(document)
    setName(document.vendorName || '')
    setOpen(true)
  }

  const handleDeleteFile = async (urlObj) => {
    setdeleteLoading(true)
    try {
      const updatedDocuments = projectDetail.documents
        .map((doc) => {
          const updatedUrls = doc?.url.filter((url) => url._id !== urlObj._id)
          if (updatedUrls.length === 0) {
            return null
          }
          return { ...doc, url: updatedUrls }
        })
        .filter((doc) => doc !== null)
      await purchaseOrderService.updateProjectOnProjectId({ ...projectDetail, documents: updatedDocuments })
       setProjectDetail({ ...projectDetail, documents: updatedDocuments })
      await showToast('Deleted', true)
      setdeleteLoading(false)
      setOpenDeleteModal(false)
    } catch (error) {
      console.log(error)
    } finally {
      setdeleteLoading(false)
    }
  }

  const handleClose = () => setOpen(false)
  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }
  const onFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setFileName(file.name)
      handleFileChange(event)
    }
  }
  const handleNameChange = (e) => setName(e.target.value)
  const handleUpload = async () => {
    if (!file && !selectedDocument?.link) {
      console.error('No file selected.')
      return
    }
    if (file && file.type !== 'application/pdf') {
      console.error('Only PDF files are allowed.')
      return
    }
    try {
      setIsLoading(true)
      let newLink = selectedDocument?.link
      let updatedVendorName = name.trim() === '' ? selectedDocument?.vendorName : name
      let updatedFileName = selectedOption || selectedDocument?.fileName
      if (file) {
        const formData = new FormData()
        formData.append('files', file)
        formData.append('SubDirectory', updatedVendorName)
        formData.append('Directory', updatedFileName)
        const response = await uploadServices.postImage(formData)
        newLink = response[0]
      }
      const existingDocumentIndex = projectDetail.documents.findIndex(
        (doc) => doc.fileName === selectedDocument?.fileName
      )
      let updatedDocuments
      if (existingDocumentIndex !== -1) {
        const existingDocument = projectDetail.documents[existingDocumentIndex]
        const updatedUrls = [
          {
            vendorName: updatedVendorName,
            link: newLink
          }
        ]
        updatedDocuments = [...projectDetail.documents]
        updatedDocuments[existingDocumentIndex] = {
          ...existingDocument,
          fileName: updatedFileName,
          url: updatedUrls
        }
      } else {
        const newDocument = {
          url: [{ vendorName: updatedVendorName, link: newLink }],
          fileName: updatedFileName
        }
        updatedDocuments = [...projectDetail.documents, newDocument]
      }
      await purchaseOrderService.updateProjectOnProjectId({
        ...projectDetail,
        documents: updatedDocuments
      })
      const updatedProject = await purchaseOrderService.getProjectOnProjectId(param.projectId)
      setProjectDetail(updatedProject.data)
      setValues(updatedProject.data)
      setFile(null)
      setName('')
      setFileName('')
      handleClose()
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const downloadProjectAndOrders = async (project, orders) => {
    const rows = []
    rows.push(['Project Details'])
    rows.push([])
    const allowedKeys = [
      'projectId',
      'vesselName',
      'freightLine',
      'bookingNumber',
      'departurePort',
      'arrivalPort',
      'arrivalWarehouse',
      'startDate',
      'loadDate',
      'cutOffDate',
      'departureDate',
      'arrivalDate',
      'deliveryDate',
      'projectStage'
    ]
    const keysRow = []
    const valuesRow = []
    for (const [key, value] of Object.entries(project)) {
      if (allowedKeys.includes(key)) {
        keysRow.push(key)
        valuesRow.push(value)
      }
    }
    rows.push(keysRow)
    rows.push(valuesRow)
    rows.push([])
    rows.push([])
    rows.push(['Purchase Orders'])
    rows.push([])
    rows.push(['PO Number', 'Supplier', 'Ship To', 'Ship Via', 'PO Date', 'PO Due Date'])
    await orders.forEach((order) => {
      rows.push([order.poNumber, order.supplier, order.shipTo, order.shipVia, order.poDate, order.poDueDate])
    })
    await exportJsonToCsvCustomRows(rows, project.projectId)
  }

  const selectOptions = [
    { label: 'ATR', value: 'ATR' },
    { label: 'Commercial Invoice', value: 'CommercialInvoice' },
    { label: 'Logistic Invoice', value: 'LogisticInvoice' },
    { label: 'Declaration', value: 'Declaration' },
    { label: 'Bill of Lading', value: 'Bill of Lading' }
  ]

  useEffect(() => {
    const fetchFilenames = async () => {
      try {
        const response = await purchaseOrderService.getProjectOnProjectId(param.projectId) // Adjust the API endpoint as needed
        const filenames = response.data.documents.map((doc) => doc.fileName)
        const distinctFilenames = [...new Set(filenames)]
        setDynamicOptions(distinctFilenames)
      } catch (error) {
        console.error('Error fetching filenames:', error)
      }
    }
    fetchFilenames()
  }, [])

  const combinedOptions = [...new Set([...selectOptions.map((option) => option.label), ...dynamicOptions])]

  return (
    <>
      <>
        <div className='p-5'>
          <Grid container spacing={2}>
            {/* Left Section - Breadcrumb and Project Details */}
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
              <h3>Projects Detail</h3>
              <p>Detail of your Projects</p>
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Grid container spacing={2} justifyContent='flex-end' alignItems='center'>
                {userData?.user?.pomUserType === 1 && userData?.user?.role >= 4 && (
                  <Grid item>
                    <ProductButton variant='outlined' onClick={handleOpen}>
                      Import Document&nbsp;&nbsp;
                      <CiImport size='1.5rem' />
                    </ProductButton>
                  </Grid>
                )}
                <Grid item>
                  <FormControl
                    fullWidth
                    sx={{
                      backgroundColor: '#F9F9F9',
                      borderColor: 'primary.main',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main'
                      },
                      '& .MuiSelect-select': {
                        color: 'primary.main'
                      }
                    }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={() => {
                        return (
                          <span>
                            Export Details&nbsp;&nbsp; <CiExport size='1.5rem' />
                          </span>
                        )
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value='Export Reports' onClick={() => downloadProjectAndOrders(projectDetail, rows)}>
                        Export Project Details
                      </MenuItem>
                      <MenuItem
                        value='Export In PDF'
                        onClick={() => {
                          downloadProjectDeliveryAndOrders(projectDetail, projectDelivery)
                        }}
                      >
                        Export Delivery Details
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {userData?.user?.pomUserType === 1 && userData?.user?.role >= 4 && (
                  <Grid item>
                    <ProductButton variant='outlined' component={Link} to={`/project/edit/${param?.projectId}`}>
                      Edit&nbsp;&nbsp;
                      <AiOutlineEdit size='1.5rem' />
                    </ProductButton>
                  </Grid>
                )}
                
                <Grid item>
                  <ProductButton variant='outlined'  component={Link} to={`/projects/delivery/${param?.projectId}`}>
                    Delivery&nbsp;&nbsp;
                    <AiOutlineAppstoreAdd size='1.5rem' />
                  </ProductButton>
                </Grid>
                <Grid item>
                  <ProductButton variant='outlined' component={Link} to={`/project/history/${param?.projectId}`}>
                    View Activity&nbsp;&nbsp;
                    <MdOutlineRemoveRedEye size='1.5rem' />
                  </ProductButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {isPoLoading ? (
              <Skeleton animation='wave' variant='rectangle' width='100%' height='350px' />
            ) : (
              <div>
                <CRow className='ms-2 mt-5'>
                  <CCol>
                    <CRow>
                      <CCol sm={6} lg={6}>
                        <CRow>
                          <CCol>
                            <h5>Project ID</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.projectId}</p>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol>
                            <h5>Start Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.startDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Vessel Name</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.vesselName}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Load Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.loadDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Freight Line</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.freightLine}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Cutoff Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.cutOffDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5> Booking Number</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.bookingNumber}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5> Container</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.container}</p>
                          </CCol>
                        </CRow>
                      </CCol>

                      <CCol sm={6} lg={6}>
                        <CRow>
                          <CCol>
                            <h5>Departure Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.departureDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Departure Port</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.departurePort}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.arrivalDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Port</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.arrivalPort}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Container Return to Port Date</h5>
                          </CCol>
                          <CCol>
                            <p>
                              {projectDetail.containerReturntoPortDate
                                ? getDate(projectDetail.containerReturntoPortDate)
                                : ''}
                            </p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Warehouse</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.arrivalWarehouse}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Delivery Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.deliveryDate)}</p>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow className='ms-2 mt-5'>
                  <CCol sm={10} lg={10} style={{ display: 'flex' }}>
                    <CCol>
                      <CFormLabel htmlFor='type' className='col-sm-12 col-form-label' data-testid='Supplier'>
                        <h5>Project Stages</h5>
                      </CFormLabel>
                    </CCol>
                    <CCol>
                      <select
                        value={values.projectStage}
                        onChange={(e) => {
                          setValues({ ...values, projectStage: e.target.value })
                        }}
                      >
                        {options.map((option, index) => {
                          return (
                            <option key={index} value={option.value} disabled={index< options.findIndex((optionStatus)=>optionStatus.value==values.projectStage)} >
                              {option.value}
                            </option>
                          )
                        })}
                      </select>
                    </CCol>
                    <CCol className='ms-5'>
                      <ProductButton variant='outlined' onClick={onStageChange}>
                        Update &nbsp; &nbsp; &nbsp;
                        <HiViewGridAdd size='1.5rem' />
                      </ProductButton>
                    </CCol>
                  </CCol>
                </CRow>
              </div>
            )}
            {Array.isArray(rows) && rows.length ? (
              <BasicTable
                columns={columns}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                projectDetail={projectDetail}
                // isLoading={isPoLoading}
                Nopagination={true}
                isProLoading={isProLoading}
              />
            ) : (
              Array.from({ length: 5 }, (_, index) => (
                <div key={index} className='mt-1'>
                  <Skeleton cols={5} animation='wave' height={50} />
                </div>
              ))
            )}
            {projectDetail && (
              <CRow className='mt-5'>
                <CCol>
                  <h4>Documents Table</h4>
                  <DocumentsTable
                    projectDetail={projectDetail}
                    handleOpen={handleOpen}
                    handleDeleteFile={handleDeleteFile}
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    deleteLoading={deleteLoading}
                  />
                </CCol>
              </CRow>
            )}
            <ChatBox
              isOpen={isOpen}
              poId={projectDetail._id}
              poNumber={projectDetail.projectId}
              userId={contextObj.userData.user.id}
              sendMessage={sendMessage}
              messages={messages}
              setIsOpen={setIsOpen}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              contextObj={contextObj}
            />
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%', // Adjusted width for better alignment
              maxWidth: '600px', // Added max-width for better responsiveness
              height: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto'
            }}
          >
            <Box sx={{ mb: 2 }}>
              <h5 id='modal-modal-title' style={{ marginBottom: '15px' }}>
                Upload Document
              </h5>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={4}>
                  <Tooltip title={'File Name'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='File Name'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={name}
                        onChange={handleNameChange}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title='Select or Write Option'>
                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small'>
                      <Autocomplete
                        freeSolo
                        value={selectedDocument ? selectedDocument.fileName : selectedOption}
                        onChange={(event, newValue) => setSelectedOption(newValue || '')}
                        inputValue={selectedOption}
                        onInputChange={(event, newInputValue) => setSelectedOption(newInputValue || '')}
                        options={combinedOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Select or Write Option'
                            size='small'
                            variant='outlined'
                            InputLabelProps={{ shrink: false }}
                          />
                        )}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <input
                    type='file'
                    accept='application/pdf,application/vnd.ms-excel'
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    id='file-upload-input'
                  />
                  <label htmlFor='file-upload-input'>
                    <MuiButton
                      component='span'
                      variant='contained'
                      color='primary'
                      sx={{
                        width: '100%',
                        padding: '10px 15px',
                        backgroundColor: '#3c4b64',
                        '&:hover': {
                          backgroundColor: '#3c4b64'
                        }
                      }}
                    >
                      Choose File
                    </MuiButton>
                  </label>
                </Grid>
                {selectedDocument?.link && (
                  <Grid item xs={8}>
                    <MuiButton
                      variant='outlined'
                      color='primary'
                      onClick={() => window.open(selectedDocument.link, '_blank')}
                      sx={{
                        padding: '10px 15px',
                        borderColor: '#3c4b64',
                        color: '#3c4b64',
                        '&:hover': {
                          borderColor: '#3c4b64',
                          backgroundColor: 'transparent',
                          color: '#3c4b64'
                        }
                      }}
                    >
                      View File
                    </MuiButton>
                  </Grid>
                )}
              </Grid>

              <Typography variant='body2' sx={{ ml: 2, mt: 2 }}>
                {fileName || 'No file chosen'}
              </Typography>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <MuiButton
                variant='contained'
                color='primary'
                onClick={handleUpload}
                sx={{
                  mr: 1,
                  background: '#3c4b64',
                  '&:hover': {
                    background: '#3c4b64'
                  }
                }}
                startIcon={isLoadings ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null}
              >
                {isLoadings ? 'Uploading...' : 'Upload'}
              </MuiButton>
              <MuiButton
                variant='outlined'
                sx={{
                  color: '#3c4b64',
                  borderColor: '#3c4b64',
                  '&:hover': {
                    borderColor: '#3c4b64',
                    backgroundColor: 'transparent',
                    color: '#3c4b64'
                  }
                }}
                onClick={handleClose}
              >
                Cancel
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </>
    </>
  )
}

export default ProjectsDetails
